import facepaint from "facepaint"
import variables from './variables.scss';
import "./theme.scss"

// width: 350px;
// @media (min-width: 576px) {
//     width: 475px;
// }
//
// /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
// @media (min-width: 768px) {
//     width: 668px;
// }
//
// /* Large devices (desktops, 992px and up) */
// @media (min-width: 992px) {
//     width: 800px;
// }
//
// /* Extra large devices (large desktops, 1200px and up) */
// @media (min-width: 1200px) {
//     width: 1000px;
// }

//width: ['350px', '475px','768px', '800px'],

export default {
    mq: facepaint([
        '@media(min-width: 420px)',
        '@media(min-width: 768px) and (orientation : portrait)',
        '@media(min-width: 1120px)'
    ]),

    headerFont: variables.headerFontFamily,
    bodyFont: `Caladea, serif`,
    textColor: '#444',

    lightBox: {
        color: 'rgba(0,0,0,1)',
        backgroundColor: 'rgba(255,255,255,0.8)',
        button: {
            normal: 'rgba(0,0,0,0.1)',
            hover: 'rgba(0,0,0,0.3)',
        }
    },

    cover: {
        inset: '24px',
        downArrow: {
            backgroundColor: 'rgba(255,255, 255, 0.5)',
        }
    },

    section: {
        margin: '5vh 0',
    },

    /**
     * Section styles
     */
    portfolioPageSection: {
        gutter: 12,
        margin: '100px 0 24px',
        fontSize: '4rem',
    }
}


