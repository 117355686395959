// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-artist-page-js": () => import("./../../../src/templates/ArtistPage.js" /* webpackChunkName: "component---src-templates-artist-page-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-studio-page-js": () => import("./../../../src/templates/StudioPage.js" /* webpackChunkName: "component---src-templates-studio-page-js" */)
}

